import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AimSpinnerColor, AimSpinnerColorType, AimSpinnerSize, AimSpinnerSizeType, } from '../model';

// TODO rex 현재 사용하는 곳에서 위치 스타일링 > 버튼과 함께 사용될때 조건과 내부 스타일링 추가 https://app.asana.com/0/0/1204317359795477/f
@Component({
  selector: 'aim-spinner',
  templateUrl: './aim-spinner.component.html',
  styleUrls: ['./aim-spinner.component.scss'],
  host: {
    class: 'aim-spinner',
    '[class]': '[color, size]',
  },
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class AimSpinnerComponent {
  @Input() public size: AimSpinnerSizeType = AimSpinnerSize.medium;
  @Input() public color: AimSpinnerColorType = AimSpinnerColor.primary;

  public get diameter(): number {
    switch (this.size) {
      case AimSpinnerSize.extraExtraSmall:
        return 16;
      case AimSpinnerSize.extraSmall:
        return 24;
      case AimSpinnerSize.small:
        return 36;
      case AimSpinnerSize.medium:
        return 48;
      case AimSpinnerSize.large:
        return 80;
    }
  }
}
