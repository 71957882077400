import { BooleanInput } from '@angular/cdk/coercion';
import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';
import {
  CoreButtonIconPosition,
  CoreButtonSize,
  CoreButtonState,
  CoreButtonTheme
} from 'aimmo-core2/app/components/core-button/core-button.model';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { AimSpinnerComponent } from '@aimmo/design-system/aim-spinner/src';
import { AimSpinnerSize } from '@aimmo/design-system/aim-spinner/model';


@Component({
  selector: 'aim-core-button',
  templateUrl: './core-button.component.html',
  styleUrls: ['./core-button.component.scss'],
  standalone: true,
  imports: [
    AimIconComponent,
    AimSpinnerComponent
  ]
})
export class CoreButtonComponent {
  @Input() public theme = CoreButtonTheme.outline;
  @Input() public rectangle = false;
  @Input() public loading = false;
  @Input() public size = CoreButtonSize.medium;
  @Input() public icon: string | null = null;
  @Input() public iconPosition = CoreButtonIconPosition.front;
  @Input() public state = CoreButtonState.normal;
  @Input({ transform: booleanAttribute }) public disabled = false;
  @Input({ transform: booleanAttribute }) public clickable = true;
  public readonly CoreButtonIconPosition = CoreButtonIconPosition;
  public readonly AimSpinnerSize = AimSpinnerSize;

  @HostBinding('class.clickable')
  public get isClickable(): BooleanInput {
    return this.clickable;
  }

  @HostBinding('class.ghost-theme')
  public get isGhostTheme(): BooleanInput {
    return this.theme === CoreButtonTheme.ghost;
  }

  @HostBinding('class.primary-theme')
  public get isPrimaryTheme(): BooleanInput {
    return this.theme === CoreButtonTheme.primary;
  }

  @HostBinding('class.active')
  public get isActive(): BooleanInput {
    return this.state === CoreButtonState.active;
  }

  @HostBinding('class.warning')
  public get isWarning(): BooleanInput {
    return this.state === CoreButtonState.warning;
  }

  @HostBinding('class.medium')
  public get isMedium(): BooleanInput {
    return this.size === CoreButtonSize.medium;
  }

  @HostBinding('class.small')
  public get isSmall(): BooleanInput {
    return this.size === CoreButtonSize.small;
  }

  @HostBinding('class.large')
  public get isLarge(): BooleanInput {
    return this.size === CoreButtonSize.large;
  }

  @HostBinding('class.disabled')
  public get isDisabled(): BooleanInput {
    return this.disabled;
  }

  @HostBinding('class.rectangle')
  public get isRectangle(): BooleanInput {
    return this.rectangle;
  }
}
