export const AimSpinnerSize = {
  extraExtraSmall: 'extra-extra-small',
  extraSmall: 'extra-small',
  small: 'small',
  medium: 'medium',
  large: 'large'
} as const;
export type AimSpinnerSizeType = typeof AimSpinnerSize[keyof typeof AimSpinnerSize];

export const AimSpinnerColor = {
  primary: 'aim-spinner-primary',
  dark: 'aim-spinner-dark',
} as const;
export type AimSpinnerColorType = typeof AimSpinnerColor[keyof typeof AimSpinnerColor];
