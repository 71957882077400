export enum CoreButtonTheme {
  primary = 'primary',
  outline = 'outline',
  ghost = 'ghost'
}

export enum CoreButtonSize {
  medium = 'medium',
  large = 'large',
  small = 'small'
}

export enum CoreButtonIconPosition {
  front = 'front',
  back = 'back'
}

export enum CoreButtonState {
  normal = 'normal',
  active = 'active',
  warning = 'warning',
}

export enum CoreButtonBorderRadius {
  round = 'round',
  square = 'square'
}
