@if (loading) {
  <aim-spinner [size]="AimSpinnerSize.extraExtraSmall"></aim-spinner>
}
@if (loading === false && !!icon && iconPosition === CoreButtonIconPosition.front) {
  <aim-icon [icon]="icon"/>
}

<ng-content/>

@if (loading === false && !!icon && iconPosition === CoreButtonIconPosition.back) {
  <aim-icon [icon]="icon"/>
}
